<template>
  <div id="app">
     <div>
        <full-page ref="fullpage" :options="options" id="fullpage">
          <div class="section section1" :style="{background: 'url('+require('../src/assets/img/1.png')+') no-repeat center center #00165d', backgroundSize: 'cover'}">
            <img :src="img[0]" alt="">
            <img :src="img[5]" class="imgArrow down" alt="" @click="$refs.fullpage.api.moveSectionDown()">
          </div>
          <div class="section  section2" :style="{background: 'url('+require('../src/assets/img/2.png')+') no-repeat center center #dff3fd', backgroundSize: 'cover'}">
            <img :src="img[1]" alt="">
             <img :src="img[5]" class="imgArrow down" alt="" @click="$refs.fullpage.api.moveSectionDown()">
          </div>
          <div class="section  section3" :style="{background: 'url('+require('../src/assets/img/3.png')+') no-repeat center center #eaf6ff', backgroundSize: 'cover'}">
            <img :src="img[2]" alt="">
            <img :src="img[5]" class="imgArrow down" alt="" @click="$refs.fullpage.api.moveSectionDown()">
          </div>
          <div class="section  section4" :style="{background: 'url('+require('../src/assets/img/4.png')+') no-repeat center center #dae6ff', backgroundSize: 'cover'}">
            <img :src="img[3]" alt="">
            <img :src="img[5]" class="imgArrow down" alt="" @click="$refs.fullpage.api.moveSectionDown()">
          </div>
          <div class="section  section5" :style="{background: 'url('+require('../src/assets/img/5.png')+') no-repeat center center #0c2474', backgroundSize: 'cover'}">
            <img :src="img[4]" alt="">
            <img :src="img[5]" class="imgArrow" alt="" @click="$refs.fullpage.api.moveSectionUp()">
          </div>
      </full-page>
    </div>
  </div>
</template>

<script>
import '../src/assets/css/fullpage.css'
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      options: {
        fitToSection: true,
        afterLoad: this.afterLoad,
        navigation: true,
        navigationTooltips: ['首页', '代理模式', '全自动托管操作', '业务模式', 'app介绍'],
        anchors: ['page1', 'page2', 'page3', 'page4', 'page5'],
        sectionSelector: '.section',
        sectionsColor: ['#00165d', '#dff3fd', '#eaf6ff', '#dae6ff', '#0c2474']
      },
      img: [
         require('./assets/img/1c.png'),
         require('./assets/img/2c.png'),
         require('./assets/img/3c.png'),
         require('./assets/img/4c.png'),
         require('./assets/img/5c.png'),
         require('./assets/img/e98e846bea2bc69aa6378d1cea1ce431.png')
      ]
    }
  },
  mounted() {
    // this.$refs.slideContainer.slideTo();
  }
}
</script>

<style>
@import url('./assets/css/fullpage.min.css');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#fp-nav ul li a span{
  background:#c2c2c2
}
#fp-nav ul li a.active span {
  background:#696969
}
.section{
  display: flex;
  position: relative;
}
.section .imgArrow{
  width:100px;
  height:80px;
  position: absolute;
  left: 50%;
  bottom: 5px;
  margin-left: -50px;
}
.section .imgArrow.down{
  transform: rotate(180deg);
}
.section .imgArrow:hover{
  opacity: 0.3;
  cursor: pointer;
}
</style>
